import { Check, Undo } from '@mui/icons-material';
import { Button, MenuItem, Paper, Select, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  TextField,
  useCreate,
  useGetList,
  useList,
  useListContext,
  useNotify,
  useUpdateMany,
  useUnselectAll,
  useUpdate,
} from 'react-admin';
import MappingsListAside from './MappingsListAside';
import MappingsListPreview from './MappingsListPreview';

export const PaymentTypes: Record<string, string> = {
  VENDOR_PAYMENT: 'Vendor',
  RENTAL_PAYMENT: 'Rental',
  EDUCATIONAL_PAYMENT: 'Education',
};

export const SettlementTypes: Record<string, string> = {
  INSTANT_PAY: 'Instant Pay',
  FAST_PAY: 'Fast Pay',
  REGULAR_PAY: 'Regular Pay',
};

const UpdateMerchantCode = ({
  data,
  record,
  selected,
  setSelected,
}: {
  data: any;
  record: any;
  selected: string;
  setSelected: any;
}) => {
  const { selectedIds } = useListContext();

  const value =
    selected && selectedIds.includes(record.id)
      ? selected
      : record.merchantCode;

  const merchantCodes = data?.reduce((acc: any, item: any) => {
    if (!acc[item.merchantId]) {
      acc[item.merchantId] = item;
    }
    return acc;
  });

  return (
    <Select
      size="small"
      sx={{
        minWidth: 200,
        p: 0,
        m: 0,
        fontSize: 14,
        '& .MuiSelect-select': {
          py: 0.15,
          backgroundColor:
            selected && selectedIds.includes(record.id)
              ? 'rgb(33 150 243 / 40%)'
              : 'transparent',
        },
      }}
      value={value}
      onChange={(d) => {
        setSelected(d.target.value);
      }}
      disabled={!selectedIds.includes(record.id)}
    >
      {data?.map((item: any) => (
        <MenuItem value={item.id}>
          {merchantCodes[item.merchantId]?.details || item.merchantId}
        </MenuItem>
      ))}
    </Select>
  );
};

const BulkActions = ({
  selected,
  setSelected,
}: {
  selected: string;
  setSelected: any;
}) => {
  const { selectedIds } = useListContext();
  const unselectAll = useUnselectAll('paymentMapping');

  // const [updateMany, { isLoading, error }] = useUpdateMany();
  const [update, { isLoading }] = useUpdate();

  const creates: string[] = selectedIds.filter((id) => id.startsWith('new-'));
  const updates: string[] = selectedIds.filter((id) => !id.startsWith('new-'));

  const notify = useNotify();

  const [create] = useCreate();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Button
        variant="outlined"
        color="inherit"
        startIcon={<Check />}
        size="small"
        disabled={!selected || !selectedIds.length}
        onClick={async () => {
          try {
            if (creates.length) {
              await Promise.all(
                creates.map((id) => {
                  return create(
                    'paymentMapping',
                    {
                      data: {
                        merchantCode: selected,
                        paymentTypeId: id.split('-')[1],
                        settlementTypeId: id.split('-')[2],
                        cardTypeId: id.split('-')[3],
                        cardBankTypeId: id.split('-')[4],
                      },
                    },
                    {
                      onSuccess: () => {
                        console.log('created');
                      },
                      onError: () => {
                        console.log('error');
                      },
                    }
                  );
                })
              );
            }

            if (updates.length) {
              await Promise.all(
                updates.map((id) => {
                  return update(
                    'paymentMapping',
                    {
                      id,
                      data: { merchantCode: selected },
                    },
                    {
                      onSuccess: () => {
                        console.log(`success: ${id}`);
                      },
                      onError: (err) => {
                        console.log(`error: ${id}`, err);
                      },
                      mutationMode: 'pessimistic',
                    }
                  );
                })
              );
            }

            // if (updates.length) {
            //   await updateMany('paymentMapping', {
            //     ids: updates,
            //     data: { merchantCode: selected },
            //   });
            // }

            setSelected('');
            unselectAll();
            notify('Merchant code updated', { type: 'success' });
          } catch (error) {
            notify('Error: Merchant code not updated', { type: 'error' });
          }
        }}
      >
        Update
      </Button>
      <Button
        startIcon={<Undo />}
        size="small"
        color="inherit"
        onClick={() => {
          setSelected('');
          unselectAll();
        }}
      >
        Undo
      </Button>
    </Stack>
  );
};

const MappingTypesList = () => {
  const [merchantCode, setMerchantCode] = useState('');
  const [tab, setTab] = useState('');
  const [selectedPaymentType, setSelectedPaymentType] = useState('');
  const [selectedSettlementType, setSelectedSettlementType] = useState('');
  const [selectedCardType, setSelectedCardType] = useState('');
  const [selectedCardBankType, setSelectedCardBankType] = useState('');

  const { data: paymentMapping = [] } = useGetList('paymentMapping', {
    pagination: { page: 1, perPage: 100 },
  });
  const { data: merchantCodes = [] } = useGetList('merchantCodes', {
    pagination: { page: 1, perPage: 100 },
  });
  const { data: paymentTypes = [] } = useGetList('paymentTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const { data: settlementTypes = [] } = useGetList('settlementTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const { data: cardTypes = [] } = useGetList('cardTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const { data: cardBankTypes = [] } = useGetList('cardBankTypes', {
    pagination: { page: 1, perPage: 100 },
  });

  const missingMappings: any = [];
  const mappings: Record<string, any> = {};

  const paymentTypeNames = paymentTypes.map((item: any) => item.name);
  const settlementTypeNames = settlementTypes
    .map((item: any) => item.name)
    .filter(
      (value: any, index: any, self: any) => self.indexOf(value) === index
    );
  const cardTypeNames = cardTypes.map((item: any) => item.name);
  const bankTypeNames = cardBankTypes
    .map((item: any) => item.name)
    .filter(
      (value: any, index: any, self: any) => self.indexOf(value) === index
    );

  paymentTypes.forEach((paymentType: any) => {
    settlementTypes
      .filter((s) => s.paymentTypeId === paymentType.id)
      .forEach((settlementType: any) => {
        cardTypes.forEach((cardType: any) => {
          cardBankTypes
            .filter((p) => p.cardType === cardType.name)
            .forEach((cardBankType: any) => {
              // filter out enabled or disabled

              if (tab === 'enabled') {
                if (
                  !paymentType.enabled ||
                  !settlementType.enabled ||
                  !cardType.enabled ||
                  !cardBankType.enabled
                )
                  return;
              } else if (tab === 'disabled') {
                if (
                  paymentType.enabled &&
                  settlementType.enabled &&
                  cardType.enabled &&
                  cardBankType.enabled
                )
                  return;
              }

              if (
                selectedPaymentType &&
                selectedPaymentType !== paymentType.name
              )
                return;

              if (
                selectedSettlementType &&
                selectedSettlementType !== settlementType.name
              )
                return;

              if (selectedCardType && selectedCardType !== cardType.name)
                return;

              if (
                selectedCardBankType &&
                selectedCardBankType !== cardBankType.name
              )
                return;

              const found = paymentMapping.find(
                (mapping: any) =>
                  mapping.paymentType === paymentType.name &&
                  mapping.settlementType === settlementType.name &&
                  mapping.cardType === cardType.name &&
                  mapping.cardBankType === cardBankType.name
              );

              if (found) {
                mappings[found.id] = found;
              }

              const foundMissing = missingMappings.find(
                (mapping: any) =>
                  mapping.paymentType === paymentType.name &&
                  mapping.settlementType === settlementType.name &&
                  mapping.cardType === cardType.name &&
                  mapping.cardBankType === cardBankType.name
              );

              if (!found && !foundMissing) {
                missingMappings.push({
                  id: `new-${paymentType.id}-${settlementType.settlementTypeId}-${cardType.id}-${cardBankType.id}`,
                  paymentType: paymentType.name,
                  settlementType: settlementType.name,
                  cardType: cardType.name,
                  cardBankType: cardBankType.name,
                });
              }
            });
        });
      });
  });

  const listContext = useList({
    data: [...Object.values(mappings), ...missingMappings],
  });

  return (
    <Stack sx={{ my: 2 }}>
      {/* <Stack>
        <MappingsListPreview paymentMapping={paymentMapping} />
      </Stack> */}
      <Stack direction="row" spacing={2} my={5}>
        <MappingsListAside
          paymentTypeChoices={paymentTypeNames}
          settlementTypeChoices={settlementTypeNames}
          cardTypeChoices={cardTypeNames}
          bankTypeChoices={bankTypeNames}
          tabChoices={['enabled', 'disabled']}
          selectedPaymentType={selectedPaymentType}
          selectedSettlementType={selectedSettlementType}
          selectedCardType={selectedCardType}
          selectedBankType={selectedCardBankType}
          selectedTab={tab}
          setSelectedPaymentType={setSelectedPaymentType}
          setSelectedSettlementType={setSelectedSettlementType}
          setSelectedCardType={setSelectedCardType}
          setSelectedBankType={setSelectedCardBankType}
          setSelectedTab={setTab}
        />
        <Paper sx={{ flex: 1 }}>
          <ListContextProvider value={listContext}>
            <Datagrid
              bulkActionButtons={
                <BulkActions
                  selected={merchantCode}
                  setSelected={setMerchantCode}
                />
              }
              rowSx={(record) => {
                return record.id.startsWith('new-')
                  ? { backgroundColor: 'rgb(33 150 243 / 10%)' }
                  : {};
              }}
            >
              <FunctionField
                label="Payment"
                source="paymentType"
                render={(record: any) => PaymentTypes[record.paymentType]}
              />

              <FunctionField
                label="Settlement"
                source="settlementType"
                render={(record: any) => SettlementTypes[record.settlementType]}
              />

              <TextField label="Card" source="cardType" />
              <TextField label="Bank" source="cardBankType" />
              <FunctionField
                label="Merchant Code"
                source="merchantCode"
                render={(record: any) => {
                  return (
                    <UpdateMerchantCode
                      record={record}
                      data={merchantCodes}
                      selected={merchantCode}
                      setSelected={setMerchantCode}
                    />
                  );
                }}
              />
            </Datagrid>
          </ListContextProvider>
        </Paper>
      </Stack>
    </Stack>
  );
};

export default MappingTypesList;
