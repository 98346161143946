import { Typography, Grid, Box, CardContent, Stack, Card } from '@mui/material';
import {
  BooleanInput,
  DateField,
  Edit,
  ImageField,
  ImageInput,
  Labeled,
  PrevNextButtons,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useEditController,
  useNotify,
  useRefresh,
} from 'react-admin';
import { CustomerDetails } from '../orders/OrderEdit';
import { apiUrl } from '../constants';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import useDecryptData from '../hooks/useDecryptData';

const styles = {
  imageWrapper: {
    '.previews': {
      display: 'flex',
      justifyContent: 'center',
      div: {
        width: '100%',
      },
    },
    img: {
      borderRadius: 1,
      width: '100%',
      objectFit: 'cover',
    },
  },
};

const updateImageToFormData = ({ formdata, data, imageKey, dataKey }: any) => {
  if (data[dataKey]?.rawFile)
    return formdata.append(imageKey, data[dataKey]?.rawFile);
  else if (data[dataKey] === null)
    return formdata.append(imageKey + 'Remove', true);
  else return;
};

const CardEdit = () => {
  const { save } = useEditController();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleSubmit = async (data: any, event: any) => {
    // if (!save) return;
    // event?.preventDefault();
    // await save({
    //   isActive: data.enabled,
    //   desc: data.desc,
    // });

    // if (!status) {
    //   notify("Please select a status", { type: "error" });
    //   return;
    // }
    // setLoading(true);

    const formdata = new FormData();
    formdata.append('name', data.name);
    formdata.append('last4Digit', data.last4Digit);
    formdata.append('cardBankType', data.cardBankType);
    formdata.append('cardBankName', data.cardBankName);
    formdata.append('cardNetworkName', data.cardNetworkName);
    formdata.append('cardType', data.cardType);
    updateImageToFormData({
      formdata,
      data,
      imageKey: 'cardFrontImage',
      dataKey: 'frontImageUrl',
    });
    updateImageToFormData({
      formdata,
      data,
      imageKey: 'cardBackImage',
      dataKey: 'backImageUrl',
    });

    fetch(`${apiUrl}/cards/${data?.id}`, {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('auth') || '',
      },
      body: formdata,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 'MSG_0037') {
          notify('Card status was updated successfully', {
            type: 'success',
          });
        } else {
          notify('Something went wrong', { type: 'error' });
        }

        refresh();
      })
      .catch((e) => {
        notify('Something went wrong', { type: 'error' });
      });
  };

  return (
    <Edit redirect="false">
      <SimpleForm onSubmit={handleSubmit}>
        <Stack
          sx={{
            flexDirection: {
              xs: 'column-reverse',
              sm: 'column-reverse',
              md: 'row',
            },
            gap: 2,
          }}
        >
          <Box>
            <PrevNextButtons
              linkType="edit"
              sort={{ field: 'createdAt', order: 'DESC' }}
            />
            <Card sx={{ mb: 10 }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={8}>
                    <Typography variant="h6" gutterBottom>
                      Card Details
                    </Typography>

                    <Grid container>
                      <Grid item xs={12} sm={12} md={6}>
                        <Labeled source="createdAt" label="Submitted On">
                          <DateField
                            source="createdAt"
                            showTime
                            options={{
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour12: true,
                              hour: 'numeric',
                              minute: 'numeric',
                            }}
                          />
                        </Labeled>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Labeled source="id" label="Reference Id">
                          <TextField source="id" />
                        </Labeled>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextInput label="Card Holder Name" source="name" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput label="Last 4 Digits" source="last4Digit" />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ReferenceInput
                          label="Bank Name"
                          source="cardBankName"
                          reference="banks"
                          // filter={{ cardTypeId }}
                          // disabled
                        >
                          <SelectInput optionValue="name" optionText="name" />
                        </ReferenceInput>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceInput
                          label="Network Name"
                          source="cardNetworkName"
                          reference="cardProviders"

                          // filter={{ cardTypeId }}
                          // disabled
                        >
                          <SelectInput optionValue="name" optionText="name" />
                        </ReferenceInput>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceInput
                          label="Card Type"
                          source="cardType"
                          reference="cardTypes"
                          // disabled
                        >
                          <SelectInput optionValue="id" optionText="name" />
                        </ReferenceInput>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CardBankTypeSection />
                      </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                      <CardImage
                        keyName={'frontImageUrl'}
                        label="Front Image"
                      />
                      <CardImage keyName={'backImageUrl'} label="Back Image" />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Typography variant="h6" gutterBottom>
                      Customer
                    </Typography>
                    <ReferenceField
                      source="userId"
                      reference="customers"
                      link={false}
                    >
                      <CustomerDetails />
                    </ReferenceField>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Stack>
      </SimpleForm>
    </Edit>
  );
};

const CardBankTypeSection = () => {
  const cardTypeId = useWatch<{ cardType: string }>({ name: 'cardType' });

  return (
    <ReferenceInput
      label="Bank Type"
      source="cardBankType"
      reference="cardBankTypes"
      filter={{ cardTypeId }}
      // disabled
    >
      <SelectInput optionValue="name" optionText="name" />
    </ReferenceInput>
  );
};

const CardImage = ({ keyName, label }: any) => {
  const imageUrl = useWatch<{ [keyName: string]: string }>({
    name: keyName,
  }) as any;

  const { data, dataLoading, decryptData, reset } = useDecryptData();
  useEffect(() => {
    imageUrl && decryptData(imageUrl);
  }, [imageUrl]);

  return (
    <Grid item xs={12} sm={6} sx={styles.imageWrapper}>
      <ImageInput source={keyName} label={label}>
        {(imageUrl?.src || data) && (
          <img src={imageUrl?.src || data} height={200} />
        )}
      </ImageInput>
    </Grid>
  );
};
export default CardEdit;
